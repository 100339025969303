









import { gql } from 'graphql-tag';
import { Component, mixins } from 'nuxt-property-decorator';

import { PageBlockMixin } from '@/modules/cms';
import { ScenkonstDigitalStageBlockDataFragment } from '@/graphql/generated';
import { digitalStageImageFragment } from '@/graphql/queries/image.fragments';

@Component({
  graphqlData: gql`
    fragment ScenkonstDigitalStageBlockData on ScenkonstDigitalStageBlock {
      id
      title
      body
      linkText
      linkUrl
      image {
        ...DigitalStageImageData
      }
    }

    ${digitalStageImageFragment}
  `,
})
export default class ScenkonstDigitalStageBlock extends mixins(PageBlockMixin<ScenkonstDigitalStageBlockDataFragment>()) {}
